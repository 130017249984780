import React, { useState } from "react";
import { Modal, Upload } from "antd";
import { postData, putData, getData, patchData } from "utils/fetchData";
import Swal from "sweetalert2";
import { reloadDatatable } from "redux/app/actions";

const tokenName = process.env.TOKEN_NAME;

export const PreviewImage = (props) => {
  const [previewVisible, setPreviewVisible] = useState(false);

  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const FileList = {
    uid: props.index.id,
    name: props.index.title,
    status: "done",
    url: props.data,
  };

  const handleCancel = () => setPreviewVisible(false);
  const handlePreview = async (file) => {
    setPreviewImage(file.url);
    setPreviewVisible(true);
    setPreviewTitle(file.name);
  };

  return (
    <div>
      <Upload
        listType="picture-card"
        fileList={[FileList]}
        onPreview={handlePreview}
      ></Upload>

      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </div>
  );
};

export const editData = (values, dataUrl, retUrl, keyWord, router) => {
  Swal.fire({
    title: "" + keyWord + " güncelleyecek",
    icon: "warning",
    text: "devam etmek istiyor musunuz?",
    showConfirmButton: true,
    showCancelButton: true,
    cancelButtonText: "Hayır",
    confirmButtonText: "Evet",
  }).then(async (confirm) => {
    if (confirm.isConfirmed) {
      await putData(dataUrl, values, true);
      await Swal.fire({
        title: "" + keyWord + " başarılı bir şekilde düzenlendi",
        icon: "success",
        showConfirmButton: false,
        timer: 1500,
      });

      router.push(retUrl);
    }
  });
};

export const createData = async (values, dataUrl, retUrl, keyWord, router) => {
  await postData(dataUrl, values, true);
  await Swal.fire({
    title: keyWord,
    icon: "success",
    showConfirmButton: false,
    timer: 1500,
  });
  router.push(retUrl);
};

// export const getLanguage = async (locale) => {
//   const data = await import(`../public/locales/${locale}/common.json`).then(
//     (module) => module.default
//   );
//   return data;
// };

export const userValidation = (value) => {
  var upperval = /(?=.*[A-Z])/;
  var lowerval = /(?=.*[a-z])/;
  if (value === undefined || value === "") {
    return Promise.reject("Lütfen Parola Giriniz!");
  }
  if (value.length < 8) {
    return Promise.reject(
      new Error("Parola uzunluğu en az 8 karakterden oluşmalıdır")
    );
  }
  if (value.length > 50) {
    return Promise.reject(
      "Parola uzunluğu en fazla 49 karakterden oluşmalıdır."
    );
  }
  if (upperval.test(value) === false || lowerval.test(value) === false) {
    return Promise.reject(
      "Parolanız en az bir büyük ve kücük harf içermelidir (A b d vb.)"
    );
  }
  if (value.search(/\d/) == -1) {
    return Promise.reject("Parolanız en az bir rakam içermelidir (1 2 3 vb.)");
  }
  if (value.search(/[\!\@\#\$\%\^\&\*\(\)\_\+\.\,\;\:\-]/) == -1) {
    return Promise.reject(
      "Parolanız en az bir simge içermelidir ('-' '_' '.' vb.)"
    );
  }
  return Promise.resolve();
};

// system logout
export const logout = () => {
  getData("/admin/Logout", true);
  window.localStorage.removeItem(process.env.TOKEN_NAME);
  window.localStorage.removeItem(process.env.USER_IMAGE);
  window.localStorage.removeItem(process.env.USER_ID);
  document.cookie = "tokenValue=";
  window.location.assign("/login");
};

// do I make the first letter of a string uppercase in JavaScript?
export function capitalizeFirstLetter(string) {
  if (typeof string != "string") return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// get access token
export const getAccessToken = () => {
  if (typeof window !== "undefined")
    return "Bearer " + localStorage.getItem(tokenName);
};

export const changeSortDatatable = (categoryId, oldOrder, dispatch) => {
  return Swal.fire({
    title: "Yeni Sıra Giriniz",
    input: "number",
    inputAttributes: {
      autocapitalize: "off",
    },
    cancelButtonText: "iptal",
    showCancelButton: true,
    confirmButtonText: "Gönder",
    showLoaderOnConfirm: true,
    preConfirm: async (login) => {
      try {
        if (parseInt(login) <= 0) {
          throw "Lütfen Geçerli Rakam Giriniz";
        }
        await postData(
          "/Admin/ChangeContentOrder",
          {
            id: categoryId,
            newOrder: parseInt(login),
            oldOrder: oldOrder,
          },
          true
        );
        dispatch(reloadDatatable());
      } catch (err) {
        Swal.showValidationMessage(`Hata : ${err}`);
      }
    },
    allowOutsideClick: () => !Swal.isLoading(),
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire({
        title: "Başarılı Bir Şekilde Düzenlendi",
        icon: "success",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  });
};

export const findElementParameter = (gelenData, name, out) => {
  //return gelenData[name][out];
  const index = gelenData?.findIndex((element) => element.name === name);
  if (index > -1 && gelenData[index][out] != null) {
    return gelenData[index][out];
  }
};

export const findElement = (gelenData, name) => {
  //return gelenData[name][out];
  const index = gelenData.findIndex((element) => element.name === name);
  if (index > -1 && [index] != null) {
    return gelenData[index];
  }
};

export const findEnumParameter = (data, id, out) => {
  // const {id=1,bool:false}
  //return gelenData[name][out];

  const index = data.findIndex((element) => element.id === id);
  if (index > -1 && data[index][out] != null) {
    return data[index][out];
  }
};

export const findEnum = (data, id) => {
  //return gelenData[name][out];
  const index = data.findIndex((element) => element.id == id);
  if (index > -1 && data[index] != null) {
    return data[index];
  }
};

export const findEnumByName = (data, title, key) => {
  const index = data.findIndex((element) => element[title] == key);
  if (index > -1 && data[index] != null) {
    return data[index];
  }
};

// TCN kimlik Kontrol
export function TCNOKontrol(TCNO) {
  var tek = 0,
    cift = 0,
    sonuc = 0,
    TCToplam = 0,
    i = 0,
    hatali = [
      11111111110, 22222222220, 33333333330, 44444444440, 55555555550,
      66666666660, 7777777770, 88888888880, 99999999990,
    ];

  if (TCNO?.length != 11) return false;
  if (isNaN(TCNO)) return false;
  if (TCNO[0] == 0) return false;

  tek =
    parseInt(TCNO[0]) +
    parseInt(TCNO[2]) +
    parseInt(TCNO[4]) +
    parseInt(TCNO[6]) +
    parseInt(TCNO[8]);
  cift =
    parseInt(TCNO[1]) +
    parseInt(TCNO[3]) +
    parseInt(TCNO[5]) +
    parseInt(TCNO[7]);

  tek = tek * 7;
  sonuc = Math.abs(tek - cift);
  if (sonuc % 10 != TCNO[9]) return false;

  for (var i = 0; i < 10; i++) {
    TCToplam += parseInt(TCNO[i]);
  }

  if (TCToplam % 10 != TCNO[10]) return false;

  if (hatali.toString().indexOf(TCNO) != -1) return false;

  return true;
}
