import { useDispatch, useSelector } from 'react-redux';
import { Layout } from 'antd';
import options from './options';
import Scrollbars from '../../utility/customScrollBar';
import Menu from '../../uielements/menu';
import appActions from '../../../redux/app/actions';
import Logo from '../../utility/logo';
import SidebarWrapper from './Sidebar.styles';
import SidebarMenu from './SidebarMenu';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

// const SubMenu = Menu.SubMenu;
// const MenuItemGroup = Menu.ItemGroup;
const { Sider } = Layout;

const {
  toggleOpenDrawer,
  changeOpenKeys,
  changeCurrent,
  toggleCollapsed,
} = appActions;

function Sidebar() {
  const dispatch = useDispatch();
  const router = useRouter();
  var {
    view,
    openKeys,
    collapsed,
    openDrawer,
    current,
    height,
  } = useSelector(state => state.App);

  const [newCurrent , setNewCurrent] = useState(); 

  const customizedTheme = useSelector(
    state => state.ThemeSwitcher.sidebarTheme
  );

  function handleClick(e) {
    dispatch(changeCurrent([e.key]));
    if (view === 'MobileView') {
      setTimeout(() => {
        dispatch(toggleCollapsed());
        // dispatch(toggleOpenDrawer());
      }, 100);

      // clearTimeout(timer);
    }
    // current = "ayarlar";
  }

  function onOpenChange(newOpenKeys) {
    const latestOpenKey = newOpenKeys.find(
      key => !(openKeys.indexOf(key) > -1)
    );
    const latestCloseKey = openKeys.find(
      key => !(newOpenKeys.indexOf(key) > -1)
    );
    let nextOpenKeys = [];
    if (latestOpenKey) {
      nextOpenKeys = getAncestorKeys(latestOpenKey).concat(latestOpenKey);
    }
    if (latestCloseKey) {
      nextOpenKeys = getAncestorKeys(latestCloseKey);
    }
    dispatch(changeOpenKeys(nextOpenKeys));
  }

  const getAncestorKeys = key => {
    const map = {
      sub3: ['sub2'],
    };
    return map[key] || [];
  };

  const isCollapsed = collapsed && !openDrawer;
  const mode = isCollapsed === true ? 'vertical' : 'inline';
  const onMouseEnter = event => {
    if (collapsed && openDrawer === false) {
      dispatch(toggleOpenDrawer());
    }
    return;
  };

  const onMouseLeave = () => {
    
    if (collapsed && openDrawer === true) {
      dispatch(toggleOpenDrawer());
    }
    return;
  };

  const styling = {
    backgroundColor: customizedTheme.backgroundColor,
  };

  const submenuStyle = {
    backgroundColor: '',
    color: customizedTheme.textColor,
  };

  const submenuColor = {
    color: customizedTheme.textColor,
  };

  const changeActiveBtn = async ()=>{
    for (let index = 0; index < options.length; index++) {
      const item = options[index];
      if(router.route.includes(item.key) && item.key != ""){
        setNewCurrent([item.key]);
        return false;
      }else if(item.key == ""){
        setNewCurrent([item.key]);
      }
    }
  }
  // change active button ( selected keys ) ....
  useEffect(()=>{
    changeActiveBtn();
  },[]);

  return (
    <SidebarWrapper>
       <Sider
        trigger={null}
        collapsible={true}
        collapsed={isCollapsed}
        width={240}
        className="isomorphicSidebar"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={styling}
        onCollapse={(value) => setCollapsed(value)}
      >
        <Logo collapsed={isCollapsed} />
        <Menu
            onClick={handleClick}           
            theme="dark"
            className="isoDashboardMenu"
            mode={mode}
            openKeys={isCollapsed ? [] : openKeys}
            selectedKeys={newCurrent}
            onOpenChange={onOpenChange}
          >
            
            {
              options.map(singleOption => (
                <SidebarMenu
                  key={singleOption.key}
                  submenuStyle={submenuStyle}
                  submenuColor={submenuColor}
                  singleOption={singleOption}
                />
              ))                                   
            }  

            {/* <SubMenu
              key="sub1"
              title={
                <span className="isoMenuHolder" style={submenuColor}>
                  <i className="ion-android-options" />
                  <span className="nav-text">
                     Sidebar Menu Levels
                  </span>
                </span>
              }
            >
              <MenuItemGroup
                key="g1"
                title={"Menu Title 1"}
              >
                <Menu.Item style={submenuStyle} key="1">
                  Sidebar Optiosn 1
                </Menu.Item>
                <Menu.Item style={submenuStyle} key="2">
                Sidebar Optiosn 2
                </Menu.Item>
              </MenuItemGroup>
              <MenuItemGroup
                key="g2"
                title={"Menu title 2"}
              >
                <Menu.Item style={submenuStyle} key="3">
                Sidebar Optiosn 3
                </Menu.Item>
                <Menu.Item style={submenuStyle} key="4">
                Sidebar Optiosn 4
                </Menu.Item>
              </MenuItemGroup>
            </SubMenu> */}
          </Menu>
      </Sider>
    </SidebarWrapper>
  );
}

function Test(){
  return(
    <h1>
      Hello World
    </h1>
  )
}

export default Sidebar;