import { handleResponse } from "utils/hataYonetimi";
import { getAccessToken } from "./services";
import axios from "axios";

const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
const api = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
  responseType: "json",
});

export const getData = async (url, istoken = false) => {
  try {
    if (istoken) {
      api.defaults.headers.common["Authorization"] = getAccessToken();
    }
    const requestOptions = {
      url: url ?? "/url Gelmedi",
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    const result = await api(requestOptions);

    // var db =  window.indexedDB.open("ViranŞehir_3");
    // db.onupgradeneeded(h=>{
    //   console.log("h : " , h);
    // })

    if (result.status != 200) {
      throw result;
    }
    return result.data;
  } catch (err) {
    var result = handleResponse(err);
    throw result;
  }
};

export const postData = async (url, post, istoken = false) => {
  try {
    if (istoken) {
      api.defaults.headers.common["Authorization"] = getAccessToken();
    }
    const requestOptions = {
      url: url ?? "/url Gelmedi",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify({ ...post }),
    };
    const result = await api(requestOptions);
    if (result.status != 200) {
      throw result;
    }
    return result.data;
  } catch (err) {
    var result = handleResponse(err);
    throw result;
  }
};
export const postFormData = async (url, post, istoken = false) => {
  try {
    if (istoken) {
      api.defaults.headers.common["Authorization"] = getAccessToken();
    }
    const requestOptions = {
      url: url ?? "/url Gelmedi",
      method: "POST",
      // headers: { "Content-Type": "application/json" },
      data: post,
    };
    const result = await api(requestOptions);
    if (result.status != 200) {
      throw result;
    }
    return result.data;
  } catch (err) {
    var result = handleResponse(err);
    throw result;
  }
};

export const putData = async (url, post, istoken = false) => {
  try {
    if (istoken) {
      api.defaults.headers.common["Authorization"] = getAccessToken();
    }
    const requestOptions = {
      url: url ?? "/url Gelmedi",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify({ ...post }),
    };
    const result = await api(requestOptions);
    return result.data;
  } catch (err) {
    var result = handleResponse(err);
    throw result;
  }
};

export const deleteData = async (url, post, istoken = false) => {
  try {
    if (istoken) {
      api.defaults.headers.common["Authorization"] = getAccessToken();
    }
    const requestOptions = {
      url: url ?? "/url Gelmedi",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify({ ...post }),
    };
    const result = await api(requestOptions);
    return result.data;
  } catch (err) {
    var result = handleResponse(err);
    throw result;
  }
};

export const patchData = async (url, post, istoken = false) => {
  try {
    const res = await fetch(`${baseUrl}${url}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: istoken && getAccessToken(),
      },
      body: JSON.stringify(post),
    });

    if (res.status != 200) {
      throw res;
    }
    const string = await res.text();
    const data = string === "" ? {} : JSON.parse(string);
    return data;
  } catch (err) {
    var result = handleResponse(err);
    throw result;
  }
};

export const getDataSelect = async (url = "/getSelect", istoken = false) => {
  try {
    const res = await fetch(`${baseUrl}${url}`, {
      method: "GET",
      headers: {
        Authorization: istoken && getAccessToken(),
      },
    });
    if (res.status != 200) {
      throw res;
    }
    const string = await res.text();
    const data = string === "" ? {} : JSON.parse(string);
    data = data.map((item) => ({
      label: `${item.text}`,
      value: item.id,
    }));
    return data;
  } catch (err) {
    var result = handleResponse(err);
    throw result;
  }
};
