import Swal from "sweetalert2";

const convertErrorsToHtml = (errors, htmlType) => {
  let html = ``;
  if (Array.isArray(errors) && errors.length > 0) {
    errors.map((err, i) => {
      html += `<ul class="callout callout-${err?.key == "error" ? "danger" : err.key != null ? err.key : "danger"
        }"> <li> ${err?.title ? `<div>${err.title}</div>` : ""}${err?.description ? err.description : ""
        }</li>
      </ul>`;
    });
  } else {
    html = `<ul class="callout callout-${errors?.key == "error"
        ? "danger"
        : errors?.key != null
          ? errors.key
          : "danger"
      }"> <li> ${errors?.title ? `<div>${errors.title}</div>` : ""}${errors?.description
        ? errors.description + " <b>(" + errors.code + ")</b>"
        : "istek sırasında bir hata oluştu ..........."
      }</li>
    
    </ul>`;
  }
  return html;
};

const ErrorProvider = (data, status) => {
  if (!data) return;
  var icon = Array.isArray(data)
    ? "info"
    : typeof data === "object"
      ? data?.key
      : "info";

  var html = convertErrorsToHtml(data);
  Swal.fire({
    title: Array.isArray(data)
      ? "Uyarı"
      : typeof data === "object"
        ? data?.title
        : "Uyarı",
    icon: icon == "danger" ? "error" : icon,
    html: html,
    showConfirmButton: false,
    showCancelButton: true,
    cancelButtonText: "Tamam",
  }).then((e) => {
    if (status == "401") {
      window.location.assign("/login")
    }
  });
};

export const ErrorProviderCallout = (response) => {
  if (!response) return;
  var html = convertErrorsToHtml(response);

  if (status === 403) {
    var y = document.getElementById("site-content");
    if (!y) return;
    y.innerHTML = `${html}`;
    return;
  }

  var x = document.getElementById("error-content");
  if (!x) return;
  x.innerHTML = `${html}`;
};

export const handleResponse = async ({response}) => {
  console.info(response);
  //Request Error
  if (response?.status != 200) {
    // information Error
    if (response?.status === 400) {
      ErrorProvider(response.data, response.status);
    }

    // For login Error
    if (response?.status === 401) {
        Swal.fire({
            title: "HATA",
            text: "oturma süresı doldu",
            icon: "warning",
            showConfirmButton: false,
            showCancelButton: true,
            focusConfirm: false,
            cancelButtonText:
                'Tamam',
        }).then((w) => {
          window.location.assign("/login");
        });
        setTimeout(()=>{
          window.location.assign("/login");
        },3500)
      // auto logout if 401 response returned from api
      return Promise.reject("oturum süresi Doldu");
      //location.reload(true);
    }

    //Authorization Error
    if (response?.status === 403) {
      ErrorProviderCallout(response.data, response.status);
    }

    //strem Error
    if (response?.status == 404) {
      Swal.fire({
        title: "UYARI",
        icon: "warning",
        text: "istediginiz bilgiler bulunamadı",
        showConfirmButton: false,
        showCancelButton: true,
        focusConfirm: false,
        cancelButtonText: "Tamam",
      });
      return Promise.reject("istediginiz bilgiler bulunamadı");
    }

    return Promise.reject("istek sırasında bir hata oluştu.");
  }

  // const newError = error ? error : "bilimeyen hata oluştu";
  return Promise.resolve(response.data, response.status);

};

export default ErrorProvider;



