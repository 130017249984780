import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/globals.scss";
import "antd/dist/antd.css";
import AdminLayout from "../layout/admin/index";
import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/swiper-bundle.min.css";
// import "swiper/swiper.scss";
import "swiper/swiper.min.css";
import "nprogress/nprogress.css";
import Head from "next/head";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { ConfigProvider } from "antd";
import tr_TR from "antd/lib/locale/tr_TR";
import "moment/locale/tr";
import dynamic from "next/dynamic";
import moment from "moment";

moment.locale("tr");

const TopProgressBar = dynamic(
  () => {
    return import("components/container/TopProgressBar");
  },
  { ssr: false }
);
const layouts = {
  admin: AdminLayout,
  none: (props) => <>{props.children}</>,
};
function MyApp({ Component, pageProps }) {
  // setup layout
  const Layout =
    layouts[Component.layout || "none"] || (({ children }) => <>{children}</>);

  return (
    <Provider store={store}>
      <ConfigProvider locale={tr_TR}>
        <Head>
          <title>PigmentSoft AdminPanel</title>
          <link
            href="https://use.fontawesome.com/releases/v5.7.0/css/all.css"
            rel="stylesheet"
          />
        </Head>
        <Layout>
          <TopProgressBar />
          <Component {...pageProps} />
        </Layout>
      </ConfigProvider>
    </Provider>
  );
}

export default dynamic(() => Promise.resolve(MyApp), {
  ssr: false,
});

// MyApp.getInitialProps = async appContext => {
//   const appProps = await App.getInitialProps(appContext);
//   const res = await getData("/Site/GetSettings");
//   if (appContext.Component.name == "Home" || appContext.ctx.pathname == "/") {
//     let mansetsFotograflar = await postData(
//       "/Site/GetMansets",
//       { pageSize: 100, currentPage: 1 },
//       false
//     );
//     return {
//       pageProps: {
//         ...appProps.pageProps,
//         result: res,
//         indexPage: true,
//         mansetsFotograflar: mansetsFotograflar
//       }
//     };
//   }
//   return {
//     pageProps: {
//       ...appProps.pageProps,
//       result: res
//     }
//   };
// };
