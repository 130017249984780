import React from "react";
import Link from "next/link";
import Image from "next/image";

// import siteConfig from "../../config/site.config";

const logo = ({ collapsed }) => {
  return (
    <div className="isoLogoWrapper">
      {collapsed ? (
        <div>
          <Link href="/">
            <img width="100%" style={{ marginTop: "30%",width:"80%" }} src="/assets/img/mini.png" alt="Logo Mini" />
            {/* <Image  src="/assets/img/mini.png" style={{ marginTop: "30%" }} alt="Logo Mini" width={500} height={500} /> */}
          </Link>
        </div>
      ) : (
        <Link href="/">
          <img style={{width :"85%"}} src="/assets/img/mini-logo.png" alt="Logo Max" />
          {/* <Image src="/assets/img/mini-logo.png" style={{borderRadius:"20px", marginTop: "2%"}} alt="Picture of the author" width={150}  height={60} /> */}
        </Link>
      )}
    </div>
  );
};
export default logo;
